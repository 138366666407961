<template>
  <div>
    <b-row v-if="countUser">
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="countUser.elderly"
          color="primary"
          statistic-title="Total de Idosos"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="countUser.responsible"
          color="warning"
          statistic-title="Total de Responsáveis "
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="countUser.company"
          color="success"
          statistic-title="Total de Parceiros"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="countUser.professional"
          color="info"
          statistic-title="Total de Profissionais"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="8" sm="6">
        <b-row>
          <b-col lg="6" sm="6">
            <statistics-profit v-if="userWeek" :userWeek="userWeek" />
          </b-col>
          <b-col lg="3" sm="6" v-if="countHealth">
              <statistic-card-vertical
              icon="FlagIcon"
              :statistic="countHealth.pharmacie"
              statistic-title="Farmácias"
              color="danger"
            />  
          </b-col>
           <b-col lg="3" sm="6" v-if="countHealth">
            <statistic-card-vertical
              icon="ThermometerIcon"
              :statistic="countHealth.medicament"
              statistic-title="Remédios"
              color="info"
            />
          </b-col>
        </b-row>
        <div>
          <!-- <system-access v-if="accessSystem" :accessSystem="accessSystem"/> -->
          <system-access v-if="accessSystem"/>
        </div>
      </b-col>
      <b-col>
        <b-card class="style-pharm">
          <h4 class="text-center">Ranking de Cliques</h4>
          <b-label>Escolha o tipo</b-label>
          <b-form-select
            id="i-evento"
            v-model="ads_category_type"
            :options="adsCategoryList"
            value-field="id"
            text-field="name"
            class="form-control"
          />
          <b-label>Escolha o mês</b-label>
          <b-form-select
            id="i-evento"
            v-model="reference_month"
            :options="referenceMonthList"
            value-field="id"
            text-field="name"
            class="form-control"
          />
          <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
            striped
            stacked="md"
            class="mt-2 responsive text-center"
          >
            <b-thead>
              <b-tr>
                <b-th>Nome</b-th>
                <b-th>Total de clicks</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in topCompanies">
                <b-td v-if="ads_category_type!='BANNER'">{{tr.name}}</b-td>
                <b-td v-if="ads_category_type=='BANNER'"> <b-img :src="tr.name" alt="Responsive image" class="img-responsive" fluid  rounded width="100px" height="150px" /></b-td>
                <b-td>{{tr.count}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticsProfit from '@/components/dashboard/StatisticsProfit'
import StatisticsOrder from '@/components/dashboard/StatisticsOrder'
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapState } from "vuex";
import { $themeColors } from '@themeConfig'
import SystemAccess from '@/components/dashboard/SystemAccess.vue'
//import AdsClicks from '@/components/dashboard/SystemAccess.vue'


export default {
  components: {
    BCard,
    BCardText,
    BLink,
    StatisticCardHorizontal,
    StatisticCardWithLineChart,
    StatisticCardVertical,
    VueApexCharts,
    StatisticsProfit,
    SystemAccess,
    StatisticsOrder
  },
  data() {
    return {
      reference_month: 'todos',
      ads_category_type: 'BANNER',
      isLoading: true,
      referenceMonthList: [
        {
          id: 'todos',
          name: 'Todos os meses'
        },
      ],
      adsCategoryList: [
        {
          id: 'BANNER',
          name: 'BANNERS'
        },
        {
          id: 'COMPANY',
          name: 'EMPRESAS'
        },
        {
          id: 'PROFESSIONAL',
          name: 'PROFISSIONAIS'
        },
      ]
    }
  },
  computed: {
    ...mapState("dashboards", ["countUser", "topCompanies", "userWeek", "countHealth", "accessSystem"]),
    ...mapState("segments", ["segments", "pagination"]),
  },
  methods: {
    ...mapActions("dashboards", ["fetchCountUser", "fetchUserWeek", "fetchCountHealth", "fetchAccessSystem"]),

   fetchSegments(pagination = true, page = 1) {
    this.$store
      .dispatch("dashboards/fetchReferenceMonths", {
        pagination: pagination,
        page: page
      })
      .then((response) => {
        this.isLoading = false;
        response.forEach((value, index) => {
          this.referenceMonthList.push({id: value.reference_month, name: value.reference_month})
        })
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
      });
    },

    fetchTopCompany(pagination = true, page = 1) {
    this.isLoading = true;
    this.$store
      .dispatch("dashboards/fetchTopCompany", {
        type: this.ads_category_type,
        reference_month: this.reference_month
      })
      .then((response) => {
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

  },
  created() {
    this.fetchCountUser();
    this.fetchUserWeek();
    this.fetchCountHealth();
    this.fetchAccessSystem();
    this.fetchTopCompany();
    this.fetchSegments();
  },
  watch: {
    reference_month(){
      this.fetchTopCompany();
    },
    ads_category_type(){
      this.fetchTopCompany();
    }
  }

}
</script>

<style>
.style-pharm {
  height: 623px;
}
</style>

<template>
  <b-card class="card-tiny-line-stats" body-class="pb-50">
    <h6 class="mb-1">Novos Usuários</h6>
    <!-- chart -->
    <b-overlay :show="isLoadingChartLine" opacity="0.70" rounded="sm">
      <!-- <hr style="background-color: transparent;"> -->
      <vue-apex-charts
        height="80"
        type="area"
        v-if="!isLoadingChartLine"
        :options="chartOptions"
        :series="chartSeries"
      />
    </b-overlay>
  </b-card>
</template>
<script>
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import axios from "@axios";
const $barColor = '#f3f3f3'
const $trackBgColor = '#EBEBEB'

export default {
  components: {
    StatisticCardWithLineChart,
    VueApexCharts
  },
   props: {
    userWeek: {
      default: function () {
        return []
      }
    }
  },
  data(){
    return {

      isLoadingChartLine: true,
      amount: null,

      consumptions: this.userWeek,

        chartSeries: null,
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 7,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            categories: ['DOM', 'SEG', 'TER ', 'QUA', 'QUI', 'SEX', 'SÁB']
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
    }
  },
  async mounted() {
    this.isLoadingChartLine = true;
    try {
      const { data: userWeek } = await axios.get(
        "user-week"
      );
      this.chartSeries = [{
        name: ['Nº'],
        data: 
          userWeek.map((u) => {
            return u.count
          }),
      }];
      this.isLoadingChartLine = false;
    } catch (e) {
      console.error(e);
    }
  },

}
</script>
<template>
  <b-card body-class="pb-50">
    <h6>Grafico 1</h6>
    <h2 class="font-weight-bolder mb-1">
      2,76k
    </h2>
    <!-- chart -->
    <chartjs-component-line-chart
      :height="75"
      :data="lineAreaChart.data"
      :options="lineAreaChart.options"
    />
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentLineChart from './componentsChartjs/LineChart.vue'
import { $themeColors } from '@themeConfig'
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
export default {
  components: {
    ChartjsComponentLineChart,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
  },
   props: {
   
  },
  data(){
    return {
      lineAreaChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: false,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 400,
                  fontColor: chartColors.labelColor,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ],
          },
        },
        data: {
          labels: [
            '7/12',
            '8/12',
            '9/12',
            '10/12',
            '11/12',
            '12/12',
            '13/12'
          ],
          datasets: [
            {
              data: [40, 55, 45, 75, 65, 55, 70],
              lineTension: 0,
              backgroundColor: chartColors.blueColor,
              pointStyle: 'circle',
              borderColor: 'transparent',
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBackgroundColor: chartColors.blueColor,
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
    }
  },
  beforeMount() {
    
  },
  watch: {
    
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

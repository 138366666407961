<template>
  <b-card>
    <b-row>
      <b-col lg="11" sm="6">
        <h4 class="text-center">Quantidade de acesso no sistema</h4>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon rounded-circle"
          title="Relatório "
          v-b-tooltip.hover.v-primary
          v-b-modal.modal-lg
        >
          <feather-icon icon="InboxIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card-body>
      <b-overlay :show="isLoadingChartBar" opacity="0.70" rounded="sm">
        <chartjs-component-horizontal-bar-chart
          :height="322"
          v-if="!isLoadingChartBar"
          :data="chartData"
          :options="chartOptions"
        />      
      </b-overlay>
    </b-card-body>
    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Lista de acessos"
    >
      <b-card-text>
        <b-row>
          <b-col>
            <label for="example-datepicker">Data Inicial</label>
            <b-form-datepicker
              id="datepicker-placeholder1"
              placeholder="Data Inicial"
              local="pt-BR"
              v-model="date_start"
            />
          </b-col>
          <b-col>
            <label for="example-datepicker">Data Final</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              placeholder="Data Final"
              local="pt-BR"
              v-model="date_end"
            />
          </b-col>
        </b-row>
        <b-table-simple
          striped
          stacked="md"
          class="mt-2 responsive text-center"
          id="tableRecordAccess"
          :item="fetchRecordAccess"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Qtd. de Acesso</b-th>
              <b-th>Data dos Acessos</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in recordAccess">
              <b-td>
                {{tr.count_access}}
              </b-td>
              <b-td>
                {{tr.date_access | dateFormat }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="tableRecordAccess"
          class="mt-1"
        />
      </b-card-text>
      <template #modal-footer>
        <b-col>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsComponentHorizontalBarChart from './componentsChartjs/HorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}


export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
    StatisticCardHorizontal
  },
  directives: {
    Ripple,
  },
  props: {
    accessSystem: {
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      count_access: null,
      aux: null,
      showCreate: false,
      search: null,
      perPage: 7,
      currentPage: 1,
      totalRows: null,
      date_start: null,
      date_end: null,

      isLoadingChartBar: true,

      chartData: null,
      
        chartOptions: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: 'top',
            labels: {
              padding: 5,
              fontColor: chartColors.labelColor,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
    }
  },
  computed: {
    ...mapState("dashboards", ["recordAccess", "pagination"]),
  },
  methods: {
    fetchRecordAccess(paginated = true, page = 1) {
      // this.isLoading = true;
      this.$store
        .dispatch("dashboards/fetchRecordAccess", {
          paginated: paginated,
          page: page,
          date_start: this.date_start,
          date_end: this.date_end,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchRecordAccess(true, value);
    },
  },
  created() {
    this.fetchRecordAccess();
  },
  watch: {
    date_start(val) {
      this.currentPage = 1;
      this.fetchRecordAccess(true, 1);
    },
    date_end(val) {
      this.currentPage = 1;
      this.fetchRecordAccess(true, 1);
    },
  },
  async mounted() {
    this.isLoadingChartBar = true;
    try {
      const { data: accessSystem } = await axios.get(
        "access-system"
      );
      this.chartData = {
        labels: ['DOM', 'SEG', 'TER ', 'QUA', 'QUI', 'SEX', 'SÁB'],
        datasets: [
          {
            data: accessSystem.map(consumption => {
              this.count_access = consumption.count
              if(this.count_access == null){
                this.aux = 0;
              }else{
                this.aux = this.count_access.elderly_access
                
              }
              return this.aux
            }),
            backgroundColor: $themeColors.info,
            borderColor: 'transparent',
            label: 'Idoso',
            fill: true,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
      
          },
          {
            data: accessSystem.map(consumption => {
              this.count_access = consumption.count
              if(this.count_access == null){
                this.aux = 0;
              }else{
                this.aux = this.count_access.responsible_access
                
              }
              return this.aux
            }),
            backgroundColor: '#A020F0',
            borderColor: 'transparent',
            label: 'Responsavel',
            fill: true,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
             
          },
          {
            data: accessSystem.map(consumption => {
              this.count_access = consumption.count
              if(this.count_access == null){
                this.aux = 0;
              }else{
                this.aux = this.count_access.guest_access
                
              }
              return this.aux
            }),
            backgroundColor: $themeColors.success,
            borderColor: 'transparent',
            label: 'Visitante',
            fill: true,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
      
          },
        ],
      };
      this.isLoadingChartBar = false;
    } catch (e) {
      console.error(e);
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
